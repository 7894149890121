import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function SelectPayoutMethods() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)

  return (
    <Background container="true">
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold ml-4 mt-4">
          Please select the payment method for your Salary Collection:
        </h1>

        <div className="p-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="salary_payment_method"
              defaultChecked={user.salary_payment_method === "Cash"}
              onChange={() => {
                saveUser({
                  salary_payment_method: "Cash",
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              htmlFor="salary_payment_method"
            >
              Self Collection in Cash
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="salary_payment_method"
              defaultChecked={user.salary_payment_method === "GIRO"}
              onChange={() => {
                saveUser({
                  salary_payment_method: "GIRO",
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              htmlFor="salary_payment_method"
            >
              GIRO Payment
            </label>
          </div>
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="p-4 d-flex">
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/signature")}
          />
          <Button
            size="medium"
            text="Next"
            onClick={() => {
              saveDraftUser(user)
              user.salary_payment_method === "Cash"
                ? navigate("/payout-option-cash")
                : navigate("/payout-option-giro")
            }}
          />
        </div>
      </div>
    </Background>
  )
}
